import React from "react";

const PDFPreview = React.memo(({ file }) => {
  if (!file) return null;

  return (
    <div className="bg-white dark:bg-gray-800 p-4 h-full">
      <h2 className="text-gray-800 dark:text-gray-100 text-lg font-medium mb-4">
        PDF Preview
      </h2>
      <iframe
        src={URL.createObjectURL(file)}
        title="PDF Preview"
        width="100%"
        height="100%"
        className="bg-white dark:bg-gray-800 rounded-lg"
      />
    </div>
  );
});

export default PDFPreview;
