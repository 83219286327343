import React, { createContext, useContext, useEffect, useState } from 'react';

// Create a new context for the user
const UserContext = createContext();

// UserProvider component to wrap around the app and provide user state
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);  // User state starts as null
  const [loading, setLoading] = useState(true);  // Loading state to handle the fetch

  const updateUserProfileImage = (newImage) => {
    setUser((prev) => ({ ...prev, profileImage: newImage }));
  };

  // Function to fetch user data using the JWT token stored in localStorage
  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');  // Get token from localStorage

      if (!token) {
        setLoading(false);  // No token means no user, stop loading
        return;
      }

      // API request to get user information (use your actual endpoint)
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}api/user`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`  // Pass the JWT token in the Authorization header
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }

      const userData = await response.json();  // Parse user data
      console.log("USER : ", userData);
      setUser(userData);  // Update the user state
    } catch (error) {
      console.error('Error fetching user data:', error);
      setUser(null);  // Ensure user is set to null on error
    } finally {
      setLoading(false);  // Fetch complete, stop loading
    }
  };

  // useEffect to fetch user data when the component mounts
  useEffect(() => {
    fetchUserData();  // Fetch user data once the app starts
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, loading, updateUserProfileImage }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the UserContext in other components
export const useUserContext = () => useContext(UserContext);
