import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../context/UserContext";
import { ThemeContext } from '../context/ThemeContext';

const Navigation = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { user, loading } = useUserContext();

  const { themeMode, enableDarkMode, enableLightMode, enableSystemPreference } = useContext(ThemeContext);

  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [systemDarkMode, setSystemDarkMode] = useState(
    window.matchMedia("(prefers-color-scheme: dark)").matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleSystemPreferenceChange = (e) => setSystemDarkMode(e.matches);
    mediaQuery.addEventListener("change", handleSystemPreferenceChange);
    return () => mediaQuery.removeEventListener("change", handleSystemPreferenceChange);
  }, []);

  const logoSrc =
    themeMode === "dark" || (themeMode === "system" && systemDarkMode)
      ? "/logo-full-dark.svg"
      : "/logo-full.svg";

  const closeBtnSrc =
    themeMode === "dark" || (themeMode === "system" && systemDarkMode)
      ? "/close_dark.svg"
      : "/close.svg";

  const isActive = (path) => location.pathname === path;

  return (
    <nav className="bg-white dark:bg-gray-800 shadow-lg rounded-full py-4 px-8 mx-auto mt-4 w-[95%] flex justify-between items-center relative z-10">
      <div className="container mx-auto flex justify-between items-center">

        {/* Left: Logo */}
        <div className="flex items-center ml-2">
          <img src={logoSrc} alt="App Logo" className="h-8" />
        </div>

        {/* Center: Navigation Links */}
        <div className="flex space-x-8 justify-center">
          <Link
            to="/dashboard"
            className={`text-sm font-medium transition-all px-4 py-2 rounded-full flex items-center ${isActive("/dashboard") ? "text-white bg-primary" : "text-gray-500 hover:text-primary"
              }`}
          >
            <img
              src={isActive("/dashboard") ? "/dashboard-icon-active.svg" : "/dashboard-icon.svg"}
              alt="Dashboard"
              className="h-5 w-5 mr-2 transition-all"
            />
            {t("menu.dashboard")}
          </Link>

          <Link
            to="/document-processing"
            className={`text-sm font-medium transition-all px-4 py-2 rounded-full flex items-center ${isActive("/document-processing") ? "text-white bg-primary" : "text-gray-500 hover:text-primary"
              }`}
          >
            <img
              src={isActive("/document-processing") ? "/processing-icon-active.svg" : "/processing-icon.svg"}
              alt="Document Processing"
              className="h-5 w-5 mr-2 transition-all"
            />
            {t("menu.document_processing")}
          </Link>

          <Link
            to="/archived-documents"
            className={`text-sm font-medium transition-all px-4 py-2 rounded-full flex items-center ${isActive("/archived-documents") ? "text-white bg-primary" : "text-gray-500 hover:text-primary"
              }`}
          >
            <img
              src={isActive("/archived-documents") ? "/archive-icon-active.svg" : "/archive-icon.svg"}
              alt="Archived Documents"
              className="h-5 w-5 mr-2 transition-all"
            />
            {t("menu.archived_documents")}
          </Link>
        </div>

        {/* Right: Theme Options, Notifications, and Settings */}
        <div className="flex items-center justify-end space-x-4 mr-2">
          {/* Theme Options */}
          <div className="relative">
            <button
              onClick={() => setOverlayVisible((prev) => !prev)}
              className="text-gray-400 hover:text-purple-600 transition"
              aria-expanded={isOverlayVisible}
              aria-label="Toggle Theme Options"
            >
              <img src="/theme-icon.svg" alt="Theme" className="h-6 w-6" />
            </button>

            {isOverlayVisible && (
              <div className="absolute bg-white dark:bg-gray-700 shadow-lg rounded-lg z-20 p-2 border border-gray-400 w-72 top-12 left-1/2 transform -translate-x-1/2">
                {/* Title and Close Button */}
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    {t("menu.title")}
                  </h3>
                  <button
                    onClick={() => setOverlayVisible(false)}
                    aria-label="Close"
                  >
                    <img
                      src={closeBtnSrc}
                      alt="Close"
                      className="h-5 w-5 p-1"
                    />
                  </button>
                </div>

                {/* Divider */}
                <div className="border-b border-gray-300 dark:border-gray-600 mb-4"></div>

                {/* Theme Selection Panel */}
                <div className="flex items-center justify-around w-full">
                  <div
                    onClick={() => {
                      enableLightMode();
                      setOverlayVisible(false);
                    }}
                    className={`cursor-pointer flex flex-col items-center ${themeMode === 'light' ? 'text-yellow-600' : 'text-gray-400'
                      }`}
                  >
                    <img src="/light_btn.svg" alt="Light Mode" className="h-8 w-8" />
                    <p className="text-base mt-2">{t("menu.light")}</p>
                  </div>
                  <div
                    onClick={() => {
                      enableDarkMode();
                      setOverlayVisible(false);
                    }}
                    className={`cursor-pointer flex flex-col items-center ${themeMode === 'dark' ? 'text-blue-600' : 'text-gray-400'
                      }`}
                  >
                    <img src="/dark_btn.svg" alt="Dark Mode" className="h-8 w-8" />
                    <p className="text-base mt-2">{t("menu.dark")}</p>
                  </div>
                  <div
                    onClick={() => {
                      enableSystemPreference();
                      setOverlayVisible(false);
                    }}
                    className={`cursor-pointer flex flex-col items-center ${themeMode === 'system' ? 'text-green-600' : 'text-gray-400'
                      }`}
                  >
                    <img src="/auto_btn.svg" alt="System Preference" className="h-8 w-8" />
                    <p className="text-base mt-2">{t("menu.auto")}</p>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Notifications Icon 
          <button className="relative text-gray-400 hover:text-purple-600 transition">
            <img src="/bell-icon.svg" alt="Notifications" className="h-6 w-6" />
            <span className="absolute top-0 right-0 h-2 w-2 bg-red-600 rounded-full"></span>
          </button>*/}

          {/* Settings Icon
          <button className="text-gray-400 hover:text-purple-600 transition">
            <img src="/settings-icon.svg" alt="Settings" className="h-6 w-6" />
          </button> */}

          {/* User Profile */}
          {!loading && user && (
            <Link
              to="/profile">
              <div className="cursor-pointer flex items-center space-x-2">
                <img
                  src={user?.profileImage || "/default-profile.png"}
                  alt="User Avatar"
                  className="h-10 w-10 rounded-full"
                />
                <div className="cursor-pointer text-left">
                  <p className="text-sm font-semibold text-gray-900 dark:text-white">
                    {user?.fullName}
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    {user?.email}
                  </p>
                </div>
              </div></Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
