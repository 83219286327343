import React from "react";
import UploadBlock from "./UploadBlock";
import { useTranslation } from "react-i18next";

const UploadSection = ({ file, onUpload }) => {
  const { t } = useTranslation();
  
  return (
    <>
      <UploadBlock onUpload={onUpload} />
      {file && (
        <div className="bg-white dark:bg-gray-700 m-7 p-4 rounded-md shadow-lg">
          <h2 className="text-gray-800 dark:text-gray-100 text-lg font-medium mb-2">
            {t("document_processing.upload_pdf_document")}
          </h2>
          <div className="flex items-center justify-between my-6">
            <div className="text-sm text-gray-600 dark:text-gray-300">{file.name}</div>
            <div className="flex-1 ml-4 bg-gray-200 rounded-full h-2">
              <div className="bg-accent h-2 rounded-full" style={{ width: "100%" }} />
            </div>
            <div className="text-gray-600 dark:text-gray-300 text-sm ml-4">100%</div>
          </div>
        </div>
      )}
    </>
  );
};

export default UploadSection;

