import React from "react";

const MessageDisplay = ({ message, messageType }) => {
  if (!message) return null;

  const icon =
    messageType === "success" ? "/success_msg.svg" : "/error_msg.svg";

  return (
    <div
      className={`py-3 px-4 rounded-md flex items-center whitespace-nowrap ${
        messageType === "success"
          ? "bg-green-200 text-green-600"
          : "bg-red-200 text-red-600"
      }`}
    >
      {/* Icon */}
      <img src={icon} alt="" className="w-5 h-5 mr-2" />

      {/* Message */}
      <span className="ml-2">{message}</span>
    </div>
  );
};

export default MessageDisplay;
