import React from 'react';
import Navigation from './Navigation';

const Layout = ({ children }) => {
  return (

    <div className="flex flex-col min-h-screen">
      {/* Navbar goes at the top */}
      <Navigation />
      {/* Main content goes below the Navbar */}
      <main className="flex-grow p-8">
        {children}
      </main>
    </div>
  );
};

export default Layout;
