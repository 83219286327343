import React from "react";
import { useTranslation } from "react-i18next";

const ActionButtons = ({ view, loading, isEditable, onProcess, onReset, onSave, onToggleEdit }) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-end items-center space-x-4 w-full">
      
      {view === "upload" ? (
        <>
          <button
            onClick={onReset}
            className="bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 px-8 py-3 border-2 rounded-lg"
          >
            {t('document_processing.cancel_button')}
          </button>
          <button
            onClick={onProcess}
            className={`bg-primary text-white px-8 py-3 rounded-lg ${loading ? "cursor-not-allowed" : ""}`}
            disabled={loading}
          >
            {loading ? t('document_processing.processing_button') : t('document_processing.analyze_button')}
          </button>
        </>
      ) : (
        <>
          {isEditable ? (
            <>
            <button
            onClick={onToggleEdit}
            className="bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 px-8 py-3 border-2 rounded-lg"
          >
            {t('document_processing.cancel_button')}
          </button>
              <button onClick={onSave} className="bg-secondary text-white px-8 py-3 rounded-lg">
                {t('document_processing.save_changes')}
              </button>
            </>
          ) : (
            <>
            <button
            onClick={onReset}
            className="bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 px-8 py-3 border-2 rounded-lg"
          >
            {t('document_processing.Restart_button')}
          </button>
              <button onClick={onToggleEdit} className="bg-accent text-white px-8 py-3 rounded-lg">
                {t('document_processing.modify_result')}
              </button>
              <button
                onClick={onSave}
                className="bg-secondary text-white px-8 py-3 rounded-lg"
                disabled={isEditable}
              >
                {t('document_processing.validate')}
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ActionButtons;
