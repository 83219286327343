import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [themeMode, setThemeMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) return savedTheme;
    return 'system'; // Default to system preference
  });

  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Check initial system theme
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'dark') return true;
    if (savedTheme === 'light') return false;

    // Default to system preference
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  useEffect(() => {
    const updateTheme = () => {
      if (themeMode === 'system') {
        setIsDarkMode(window.matchMedia('(prefers-color-scheme: dark)').matches);
      }
    };

    // Update theme when system preference changes
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', updateTheme);

    // Cleanup listener on unmount
    return () => mediaQuery.removeEventListener('change', updateTheme);
  }, [themeMode]);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }

    if (themeMode !== 'system') {
      localStorage.setItem('theme', themeMode);
    } else {
      localStorage.removeItem('theme'); // System preference doesn't need storage
    }
  }, [isDarkMode, themeMode]);

  const enableDarkMode = () => {
    setThemeMode('dark');
    setIsDarkMode(true);
  };

  const enableLightMode = () => {
    setThemeMode('light');
    setIsDarkMode(false);
  };

  const enableSystemPreference = () => {
    setThemeMode('system');
    setIsDarkMode(window.matchMedia('(prefers-color-scheme: dark)').matches);
  };

  return (
    <ThemeContext.Provider value={{ themeMode, enableDarkMode, enableLightMode, enableSystemPreference, isDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
