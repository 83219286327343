import React, { useState, Suspense } from "react";
import { useTranslation } from "react-i18next";
import UploadSection from "./UploadSection";
import PDFPreview from "./PDFPreview";
import MessageDisplay from "./MessageDisplay";
import ActionButtons from "./ActionButtons";
import { useUserContext } from "../context/UserContext"; // Import UserContext hook

// Lazy-loaded components
const ResultPanel = React.lazy(() => import("./ResultPanel"));

const DocumentProcessingPage = () => {
  const [file, setFile] = useState(null);
  const [ocrFields, setOcrFields] = useState(null);
  const [editableFields, setEditableFields] = useState({}); // New state for editable fields
  const [originalFields, setOriginalFields] = useState({}); // Snapshot of original fields
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null); // success or error
  const [view, setView] = useState("upload");
  const [isEditable, setIsEditable] = useState(false); // New state for edit mode
  const { t } = useTranslation();
  const { user } = useUserContext(); // Get user data from UserContext
 
  const handleReset = () => {
    setFile(null);
    setOcrFields(null);
    setEditableFields({}); // Reset editable fields
    setOriginalFields({}); // Reset original fields
    setIsEditable(false);
    setMessage(null);
    setMessageType(null);
    setView("upload");
  };

  const handleResetAfterSave = () => {
    setFile(null);
    setOcrFields(null);
    setEditableFields({}); // Reset editable fields
    setOriginalFields({}); // Reset original fields
    setIsEditable(false);
    setView("upload");
  
    // Add a delay of 3 seconds before setting the message and messageType
    setTimeout(() => {
      setMessage(null);
      setMessageType(null);
    }, 3000); // 3000ms = 3 seconds
  };
  

  const handleProcessDocument = async () => {
    if (!file) {
      setMessageType("error");
      setMessage(t("document_processing.no_file_uploaded"));
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("model", "prebuilt-invoice");
      const token = localStorage.getItem("token");

      const response = await fetch(
       `${process.env.REACT_APP_BACKEND_URI}api/analyze-document`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) throw new Error("Failed to process document");

      const data = await response.json();
      setOcrFields(data[0]);
      setEditableFields(data[0]); // Set the editable fields to the fetched data
      setOriginalFields(data[0]); // Save the original fields
      setView("result");
    } catch (err) {
      setMessageType("error");
      setMessage(err.message || "Error processing the document.");
    } finally {
      setLoading(false);
    }
  };

  const handleSaveDocument = async (updatedFields) => {
    if (!file) {
      setMessageType("error");
      setMessage(t("document_processing.no_file_uploaded"));
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("invoiceData", JSON.stringify(updatedFields));
      formData.append("file", file);
      formData.append("user",user?.email || "");
      console.log("Doc Created by: ", user?.email || "");

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}api/save-document`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      });

      if (!response.ok) throw new Error(t("document_processing.failedSaving"));
      setMessageType("success");
      setMessage(t("document_processing.SuccessSaving"));
      setOriginalFields(updatedFields);
      setIsEditable(false);
      handleResetAfterSave();
    } catch (error) {
      setMessageType("error");
      setMessage(error.message);
    }
  };

  const toggleEditMode = () => {
    if (isEditable) {

      // Cancel editing and revert changes to original fields

      setEditableFields(originalFields);

    }
    setIsEditable(!isEditable);
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow-lg rounded-2xl py-4 px-2 mx-auto mt-4 w-[98%] h-[calc(85vh-20px)]">
      <div className="h-full flex flex-col">
        <div className="flex-1 grid grid-cols-2 gap-8 overflow-hidden">
          <div className="space-y-4 overflow-y-auto">
            {view === "upload" ? (
              <UploadSection file={file} onUpload={setFile} />
            ) : (
              <Suspense fallback={<div>Loading...</div>}>
                <ResultPanel
                  ocrFields={ocrFields}
                  isEditable={isEditable}
                  editableFields={editableFields}
                  setEditableFields={setEditableFields}
                />
              </Suspense>
            )}
          </div>

          {/* Right Column: PDF Preview */}
          {file && <PDFPreview file={file} />}
        </div>

        {/* Footer */}
        <div
          className={`mt-4 flex items-center py-4 px-4 border-t font-medium ${
            message ? "justify-between" : "justify-end"
          }`}
        >
          {/* Message Display */}
          {message && (
            <div className="flex-grow flex justify-start">
              <MessageDisplay message={message} messageType={messageType} />
            </div>
          )}

          {/* Action Buttons */}
          <div className="flex justify-end">
            <ActionButtons
              view={view}
              loading={loading}
              isEditable={isEditable}
              onProcess={handleProcessDocument}
              onReset={handleReset}
              onSave={() => handleSaveDocument(editableFields)} // Use updated editableFields here
              onToggleEdit={toggleEditMode}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentProcessingPage;
