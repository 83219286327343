import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const UploadBlock = ({ onUpload }) => {
  const [dragOver, setDragOver] = useState(false);
  const [error, setError] = useState(null); // To handle errors for invalid files
  const { t } = useTranslation();

  // Handle drag over effect
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  // Handle drag leave effect
  const handleDragLeave = () => {
    setDragOver(false);
  };

  // Handle file drop
  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    setError(null); // Reset error

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const droppedFile = e.dataTransfer.files[0];

      // Validate file type
      if (droppedFile.type !== "application/pdf") {
        setError(t("document_processing.invalid_file_format"));
      } else {
        onUpload(droppedFile);
      }
    }
  };

  // Handle file input change
  const handleFileInput = (e) => {
    setError(null); // Reset error

    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];

      // Validate file type
      if (selectedFile.type !== "application/pdf") {
        setError(t("document_processing.invalid_file_format"));
      } else {
        onUpload(selectedFile);
      }
    }
  };

  return (
    <div>
      <h2 className="text-gray-800 dark:text-gray-100 text-lg font-medium m-4">
        {t("document_processing.upload_pdf")}
      </h2>

      <div
        className={`border-2 border-dashed rounded-lg p-6 text-center m-8 cursor-pointer transition-all ${
          dragOver ? "bg-blue-100 dark:bg-blue-700" : "bg-white dark:bg-gray-800"
        }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          type="file"
          className="hidden"
          id="file-upload"
          accept="application/pdf" // Accept only PDF files
          onChange={handleFileInput}
        />
        <label htmlFor="file-upload" className="cursor-pointer">
          <div className="flex flex-col items-center justify-center">
            <img src="/upload-icon.svg" alt="Upload" className="mb-4" />
            <span>
              <span className="text-accent font-semibold cursor-pointer">
                {t("document_processing.upload_prompt")}
              </span>
              <label className="text-gray-500 dark:text-gray-300 cursor-pointer">
                {t("document_processing.dragndrop")}
              </label>
            </span>
            <span className="text-gray-500 dark:text-gray-300">
              {t("document_processing.file_format")}
            </span>
          </div>
        </label>
      </div>

      {/* Show error message for invalid files */}
      {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
    </div>
  );
};

export default UploadBlock;
