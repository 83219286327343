import React from 'react';
import { Navigate } from 'react-router-dom';

// Helper function to check if the JWT token is valid
const isAuthenticated = () => {
  const token = localStorage.getItem('token'); // Get the JWT token from localStorage
  if (!token) return false;

  try {
    const decoded = JSON.parse(atob(token.split('.')[1])); // Decode the token
    const currentTime = Date.now() / 1000; // Get current time in seconds
    console.log("Expire time : ", decoded.exp);
    console.log("Current time : ", currentTime);
    return decoded.exp > currentTime; // Check if the token is expired
  } catch (error) {
    return false;
  }
};

// Protected Route component
const ProtectedRoute = ({ children }) => {
  if (isAuthenticated()) {
    return children;
  }
  
  localStorage.removeItem('token');
  return <Navigate to="/?sessionExpired=true" />;
};

export default ProtectedRoute;

