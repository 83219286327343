import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import Dashboard from "./pages/Dashboard";
import DocumentProcessing from "./pages/DocumentProcessing";
import ArchivedDocuments from "./pages/ArchivedDocuments";
import ProfilePage from "./pages/ProfilePage";
import ProtectedRoute from "./components/ProtectedRoute";
import Layout from "./components/Layout";
import { UserProvider } from "./context/UserContext";
import ThemeProvider from "./context/ThemeContext"; // Add the ThemeProvider
import useTokenExpiration from "./hooks/useTokenExpiration";

function App() {
  return (
    <UserProvider>
      <ThemeProvider>
        <Router>
          <TokenExpirationHandler /> {/* Move hook usage here */}
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Layout>
                    <Dashboard />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/document-processing"
              element={
                <ProtectedRoute>
                  <Layout>
                    <DocumentProcessing />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/archived-documents"
              element={
                <ProtectedRoute>
                  <Layout>
                    <ArchivedDocuments />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Layout>
                    <ProfilePage />
                  </Layout>
                </ProtectedRoute>
              }
            />

          </Routes>
        </Router>
      </ThemeProvider>
    </UserProvider>
  );
}

function TokenExpirationHandler() {
  useTokenExpiration(); // Call the hook inside the Router context
  return null; // This component doesn't render anything
}

export default App;
