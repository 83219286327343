import React from 'react';
import ReactDOM from 'react-dom/client'; // Import createRoot from 'react-dom/client'
import './index.css';
import App from './App';
import ThemeProvider from './context/ThemeContext';
import LanguageProvider from './context/LanguageContext';
import './i18n'; // Import your i18n configuration

// Create the root element
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the app using createRoot instead of render
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </ThemeProvider>
  </React.StrictMode>
);
