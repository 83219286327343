import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Change this to a named import
import { useUserContext } from "../context/UserContext";

const LoginPage = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const { setUser } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();

  const isTokenValid = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp > currentTime;
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get("sessionExpired")) {
      setSessionExpired(true);
    }
  }, [location]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && isTokenValid(token)) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}api/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || t("login.error"));
      }

      localStorage.setItem("token", data.token);
      localStorage.setItem("expiresAt", data.expiresAt);
      setUser(data.userProfil);
      console.log("set user data : ",data.userProfil );
      navigate("/dashboard");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="h-screen bg-cover bg-center flex justify-center items-center"
      style={{ backgroundImage: `url('/home.svg')` }}
    >
      <div className="bg-white dark:bg-gray-800 bg-opacity-90 dark:bg-opacity-90 p-8 rounded-3xl shadow-lg max-w-md w-full text-center font-dm-sans">
        <img src="/logo.svg" alt="Logo" className="w-16 mx-auto m-5" />
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white mb-2">
          {t("login.title")}
        </h2>
        <p className="text-gray-600 dark:text-gray-300 mb-6">
          {t("login.welcome_message")} <br /> {t("login.instruction")}
        </p>

        {error && <div className="text-red-500 text-sm mb-4">{error}</div>}

        {sessionExpired && (
          <div className="text-red-600 dark:text-red-600 mb-6 alert alert-warning">
            {t("login.session_expired")}
          </div>
        )}

        <form onSubmit={handleLogin} className="space-y-4">
          <div className="text-left">
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-4 py-3 border border-gray-200 dark:border-gray-600 rounded-t-lg bg-gray-50 dark:bg-gray-700 text-gray-700 dark:text-gray-300 placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:border-gray-300 transition"
              placeholder={t("login.email")}
            />

            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="mb-10 w-full px-4 py-3 border border-gray-200 dark:border-gray-600 rounded-b-lg bg-gray-50 dark:bg-gray-700 text-gray-700 dark:text-gray-300 placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:border-gray-300 transition"
              placeholder={t("login.password")}
            />
          </div>

          <button
            type="submit"
            disabled={loading}
            className="font-semibold w-full py-3 bg-gradient-to-r from-accent to-primary text-white rounded-lg hover:from-secondary hover:to-accent focus:outline-none focus:ring-2 focus:ring-primary transition"
          >
            {loading ? t("login.loading") : t("login.button")}
          </button>
        </form>

        <p className="mt-10 text-sm text-gray-600 dark:text-gray-300">
          {t("login.no_account")}{" "}
          <a href="/request-access" className="text-accent hover:underline">
            {t("login.request_access")}
          </a>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
