import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; // For i18next translations
import { Chart } from "react-google-charts";
import { ThemeContext } from "../context/ThemeContext";
import axios from "axios";

const formatNumber = (value, locale) => {
  return new Intl.NumberFormat(locale).format(value);
};

const Dashboard = () => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language || "en-US";
  const { isDarkMode } = useContext(ThemeContext);

  const [dashboardData, setDashboardData] = useState({
    currentMonth: {
      invoiceCount: 0,
      hadCount: 0,
      totalCalls: 0,
    },
    documentsByCategoryAndMonth: [],
  });

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URI}api/dashboard/kpis`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setDashboardData(response.data);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDashboardData();
  }, []);

  const { currentMonth, documentsByCategoryAndMonth } = dashboardData;

  // Prepare data for the graph
  const graphData = [["Month", t("dashboard.invoice"), t("dashboard.had")]];
  const groupedData = documentsByCategoryAndMonth.reduce((acc, doc) => {
    const date = new Date(doc._id.year, doc._id.month - 1);
    const key = date.toLocaleString(locale, { month: "short" }); // MMM format
    if (!acc[key]) {
      acc[key] = { month: key, Invoice: 0, HAD: 0 };
    }
    acc[key][doc._id.category] = doc.count;
    return acc;
  }, {});

  for (const key in groupedData) {
    const { month, Invoice, HAD } = groupedData[key];
    graphData.push([month, Invoice || 0, HAD || 0]);
  }

  // Chart options to customize appearance
  const options = {
    title: `${new Date().getFullYear()}`,
    curveType: "function",
    lineWidth: 4,
    legend: {
      position: "right",
      textStyle: { color: isDarkMode ? "#ffffff" : "#000000" },
    },
    chartArea: { width: "85%", height: "70%" },
    backgroundColor: isDarkMode ? "#212936" : "#FFFFFF",
    hAxis: {
      title: t("dashboard.month"),
      textStyle: { color: isDarkMode ? "#ffffff" : "#000000" },
      titleTextStyle: { color: isDarkMode ? "#ffffff" : "#000000" },
    },
    vAxis: {
      title: t("dashboard.amount"),
      textStyle: { color: isDarkMode ? "#ffffff" : "#000000" },
      titleTextStyle: { color: isDarkMode ? "#ffffff" : "#000000" },
    },
    colors: ["#BF9000", "#558874"], // Updated colors
    gridlines: {
      color: isDarkMode ? "#666666" : "#e9e9e9",
    },
  };

  const currentMonthName = new Date().toLocaleString(locale, { month: "long" });

  return (
    <div className="p-8 bg-gray-50 min-h-screen dark:bg-gray-900 font-dm-sans">
      {/* Top Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        {/* Tile 1: Invoice Count */}
        <div className="bg-white dark:bg-gray-800 shadow-md rounded-2xl p-4 flex items-center">
          <div className="flex-shrink-0 mr-4">
            <img
              src={isDarkMode ? "/glb_flow_dark.svg" : "/glb_flow.svg"}
              alt="Invoice Icon"
            />
          </div>
          <div>
            <h2 className="text-base font-semibold text-gray-500 dark:text-gray-400 mb-1">
              {t("dashboard.invoice_count")}
            </h2>
            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
              ({currentMonthName})
            </p>
            <p className="text-3xl font-bold text-gray-900 dark:text-white">
              {formatNumber(currentMonth.invoiceCount, locale)}
            </p>
          </div>
        </div>

        {/* Tile 2: HAD Count */}
        <div className="bg-white dark:bg-gray-800 shadow-md rounded-2xl p-4 flex items-center">
          <div className="flex-shrink-0 mr-4">
            <img
              src={isDarkMode ? "/glb_flow_dark.svg" : "/glb_flow.svg"}
              alt="HAD Icon"
            />
          </div>
          <div>
            <h2 className="text-base font-semibold text-gray-500 dark:text-gray-400 mb-1">
              {t("dashboard.had_count")}
            </h2>
            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
              ({currentMonthName})
            </p>
            <p className="text-3xl font-bold text-gray-900 dark:text-white">
              {formatNumber(currentMonth.hadCount, locale)}
            </p>
          </div>
        </div>

        {/* Tile 3: Total Calls */}
        <div className="bg-white dark:bg-gray-800 shadow-md rounded-2xl p-4 flex items-center">
          <div className="flex-shrink-0 mr-4">
            <img
              src={isDarkMode ? "/inv6_flow_dark.svg" : "/inv6_flow.svg"}
              alt="Calls Icon"
            />
          </div>
          <div>
            <h2 className="text-base font-semibold text-gray-500 dark:text-gray-400 mb-1">
              {t("dashboard.total_calls")}
            </h2>
            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
              ({currentMonthName})
            </p>
            <p className="text-3xl font-bold text-gray-900 dark:text-white">
              {formatNumber(currentMonth.totalCalls, locale)}
            </p>
          </div>
        </div>
      </div>

      {/* Documents by Category and Month Graph */}
      <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-base font-semibold text-gray-900 dark:text-white mb-4">
          {t("dashboard.documents_by_category")} ({new Date().getFullYear()})
        </h2>
        <Chart
          chartType="LineChart"
          width="100%"
          height="350px"
          data={graphData}
          options={options}
        />
      </div>
    </div>
  );
};

export default Dashboard;
