import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { LanguageContext } from "../context/LanguageContext";
import { useUserContext } from "../context/UserContext";

const ProfilePage = () => {
  const { t } = useTranslation();
  const { language, changeLanguage } = useContext(LanguageContext);
  const { user, updateUserProfileImage } = useUserContext();

  const [currentTab, setCurrentTab] = useState("general");
  const [uploadPreview, setUploadPreview] = useState(user?.profileImage);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  // Message state
  const [message, setMessage] = useState({ type: "", content: "" });

  const handleTabChange = (tab) => setCurrentTab(tab);

  const handleImageUpload = async (file) => {
    if (file && file.size <= 1024 * 1024 * 10) { // Allow files up to 10MB
      try {
        const formData = new FormData();
        const token = localStorage.getItem("token");
        const userEmail = user?.email;
  
        if (!userEmail) {
          setMessage({
            type: "error",
            content: t("profile.missingEmailError"),
          });
          return;
        }
  
        // Append file and email to FormData
        formData.append("profileImage", file);
        formData.append("email", userEmail);
  
        // Preview image on upload
        const previewUrl = URL.createObjectURL(file);
        setUploadPreview(previewUrl);
  
        console.log(`Uploading file of size: ${file.size} bytes`);
  
        // Send to the backend
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URI}api/user/upload-profile-image`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data", // Proper content type for file uploads
            },
          }
        );
  
        // Update UserContext with the new profile image
        if (response.data.profileImage) {
          updateUserProfileImage(response.data.profileImage);
        }
  
        setMessage({
          type: "success",
          content: t("profile.imageUpdated"),
        });
      } catch (error) {
        console.error("Image upload error:", error);
        setMessage({
          type: "error",
          content: t("profile.imageUpdateError"),
        });
      }
    } else {
      setMessage({
        type: "error",
        content: t("profile.imageSizeError"),
      });
    }
  };
  

  const handlePasswordChange = async () => {
    if (newPassword !== confirmNewPassword) {
      setMessage({ type: "error", content: t("profile.passwordMismatch") });
      return;
    }

    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}api/user/update-password`,
        { currentPassword, newPassword },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setMessage({ type: "success", content: t("profile.passwordUpdated") });
      setCurrentPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    } catch (error) {
      setMessage({ type: "error", content: t("profile.passwordUpdateError") });
    }
  };

  const ProfileImageUploader = () => (
    <div className="flex items-center space-x-6">
      {/* Profile Photo Viewer */}
      <div className="relative w-24 h-24">
        <img
          src={uploadPreview || "/default-profile.png"}
          alt="Profile"
          className="w-full h-full rounded-full border border-gray-300 dark:border-gray-700 object-cover"
        />
        {/* Pen Icon Overlay */}
        <div
          onClick={() => document.getElementById("profilePicUpload").click()}
          className="absolute bottom-0 right-0 w-8 h-8 bg-primary rounded-full flex items-center justify-center cursor-pointer border border-white dark:border-gray-800"
        >
          <img src="/pen_icon.svg" alt="Edit" className="w-6 h-6 text-white" />
        </div>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => handleImageUpload(e.target.files[0])}
          className="hidden"
          id="profilePicUpload"
        />
      </div>
      <p className="text-gray-600 dark:text-gray-300 italic text-xs">
        {t("profile.photoUpdatePrompt")}
      </p>
    </div>
  );

  return (
    <div className="p-8 bg-white dark:bg-gray-800 rounded-lg shadow-md mx-auto w-[98%]">
      <h1 className="text-3xl font-bold mb-6 text-gray-900 dark:text-gray-200">
        {t("profile.title")}
      </h1>

      {/* Message Zone */}
      {message.content && (
        <div
          className={`mb-6 px-4 py-3 rounded-md text-center ${message.type === "success"
            ? "bg-green-100 text-green-700 dark:bg-green-900 dark:text-green-300"
            : "bg-red-100 text-red-700 dark:bg-red-900 dark:text-red-300"
            }`}
        >
          {message.content}
        </div>
      )}

      {/* Tabs */}
      <div className="flex border-b border-gray-300 dark:border-gray-700 mb-6">
        <button
          className={`px-4 py-2 text-sm ${currentTab === "general"
            ? "text-secondary border-b-2 border-accent dark:text-secondary font-bold"
            : "text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 font-medium"
            }`}
          onClick={() => handleTabChange("general")}
        >
          {t("profile.general")}
        </button>
        <button
          className={`px-4 py-2 text-sm ${currentTab === "security"
            ? "text-secondary border-b-2 border-accent dark:text-secondary font-bold"
            : "text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 font-medium"
            }`}
          onClick={() => handleTabChange("security")}
        >
          {t("profile.security")}
        </button>
      </div>

      {/* General Tab */}
      {currentTab === "general" && (
        <div className="space-y-8">
          <ProfileImageUploader />

          <hr className="border-gray-300 dark:border-gray-700" />

          {/* Full Name Section */}
          <div className="flex items-center space-x-4">
            <label className="w-1/4 text-gray-900 dark:text-gray-200">
              <strong>{t("profile.name")}:</strong>
            </label>
            <p className="w-3/4 text-gray-900 dark:text-gray-200">{user?.fullName}</p>
          </div>

          <hr className="border-gray-300 dark:border-gray-700" />

          {/* Email Section */}
          <div className="flex items-center space-x-4">
            <label className="w-1/4 text-gray-900 dark:text-gray-200">
              <strong>{t("profile.email")}:</strong>
            </label>
            <p className="w-3/4 text-gray-900 dark:text-gray-200">{user?.email}</p>
          </div>

          <hr className="border-gray-300 dark:border-gray-700" />

          {/* Preferences Section */}
          <div>
            <h3 className="text-lg font-medium text-gray-900 dark:text-gray-200 mb-4">
              {t("profile.preferences")}
            </h3>
            <div className="flex items-center">
              <label className="w-1/4 text-gray-900 dark:text-gray-200">
                {t("profile.language")}
              </label>
              <select
                value={language}
                onChange={(e) => changeLanguage(e.target.value)}
                className="w-full max-w-[600px] px-4 py-2 border rounded-md dark:bg-gray-800 dark:text-gray-200"
              >
                <option value="en">🇬🇧 English</option>
                <option value="fr">🇫🇷 Français</option>
                <option value="de">🇩🇪 Deutsch</option>
                <option value="it">🇮🇹 Italiano</option>
                <option value="es">🇪🇸 Español</option>
              </select>
            </div>
          </div>
        </div>
      )}


      {/* Security Tab */}
      {currentTab === "security" && (
        <div className="space-y-16">
          <div className="space-y-8">
            <div className="flex items-center">
              <label className="w-1/4 text-gray-900 dark:text-gray-200">
                {t("profile.currentPassword")}
              </label>
              <input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="w-full max-w-[600px] px-3 py-2 border rounded-md dark:bg-gray-800 dark:text-gray-200"
              />
            </div>
            <hr className="border-gray-300 dark:border-gray-700" />
            <div className="flex items-center">
              <label className="w-1/4 text-gray-900 dark:text-gray-200">
                {t("profile.newPassword")}
              </label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full max-w-[600px] px-3 py-2 border rounded-md dark:bg-gray-800 dark:text-gray-200"
              />
            </div>
            <hr className="border-gray-300 dark:border-gray-700" />
            <div className="flex items-center">
              <label className="w-1/4 text-gray-900 dark:text-gray-200">
                {t("profile.confirmNewPassword")}
              </label>
              <input
                type="password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                className="w-full max-w-[600px] px-3 py-2 border rounded-md dark:bg-gray-800 dark:text-gray-200"
              />
            </div>
          </div>
          {newPassword && confirmNewPassword && newPassword !== confirmNewPassword && (
            <p className="text-red-500 dark:text-red-300">
              {t("profile.passwordMismatch")}
            </p>
          )}
          <div className="flex justify-end space-x-2 mt-8">
            <button className="bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 px-8 py-3 border-2 rounded-lg">
              {t("profile.cancel")}
            </button>
            <button
              onClick={handlePasswordChange}
              className="px-4 py-2 bg-secondary text-white rounded-md"
              disabled={
                !currentPassword ||
                !newPassword ||
                !confirmNewPassword ||
                newPassword !== confirmNewPassword
              }
            >
              {t("profile.updatePassword")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;
