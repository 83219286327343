import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios"; // For making HTTP requests
import { format } from "date-fns";

const ArchivedDocuments = () => {
  const { t, i18n } = useTranslation();
  const [documents, setDocuments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const itemsPerPage = 10;

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage(null); // Clear the message after 5 seconds
      }, 5000);
      return () => clearTimeout(timer); // Clean up the timer
    }
  }, [message]);

  // Fetch the archived documents from the backend API
  useEffect(() => {
    const fetchDocuments = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token"); // Get JWT token
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URI}api/archived-documents`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const { documents } = response.data;
        const tPages = response.data.totalPages;

        console.log("response.data", response.data);
        setDocuments(documents);
        setTotalPages(tPages);
        setFilteredDocuments(documents);
        setMessage(null); // Clear any previous error messages
      } catch (error) {
        setMessage(t("archived_documents.error_fetching_documents"));
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, [t]);

  // Handle search and filtering
  useEffect(() => {
    if (searchTerm) {
      const filtered = documents.filter(
        (doc) =>
          doc.invoiceId.toLowerCase().includes(searchTerm.toLowerCase()) ||
          doc.customerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          doc.vendorName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          doc.vendorSAPCode.toLowerCase().includes(searchTerm.toLowerCase()) ||
          doc.trigram.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredDocuments(filtered);
      setTotalPages(Math.ceil(filteredDocuments.length / itemsPerPage));
    } else {
      setFilteredDocuments(documents);
      setTotalPages(Math.ceil(filteredDocuments.length / itemsPerPage));
    }
  }, [searchTerm, documents, filteredDocuments.length]);

  // Pagination logic
  const paginatedDocuments = filteredDocuments.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md w-[98%]">
      <div className="flex justify-between items-center mb-8">
        {/* H1 Title aligned to the left */}
        <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
          {t("archived_documents.title")}
        </h1>

        {/* Search and Filter aligned to the right */}
        <div className="flex space-x-4 items-center">
          {/* Search Field */}
          <div className="relative w-80 min-w-[220px]">
            <img
              src="/search_input.svg"
              alt="search icon"
              className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400"
            />
            <input
              type="text"
              placeholder={t("archived_documents.search_placeholder")}
              className="pl-10 w-full rounded-lg px-4 py-2 dark:bg-gray-700 dark:text-gray-200 bg-gray-100 text-gray-600 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {/* Filter Button */}
          <button className="flex items-center bg-gray-100 dark:bg-gray-600 px-4 py-2 rounded-lg text-gray-700 dark:text-gray-200 shadow-sm">
            <img
              src="/filter_icon.svg"
              alt="filter icon"
              className="w-5 h-5 mr-2 dark:hidden"
            />
            <img
              src="/filter_icon_dark.svg"
              alt="filter icon dark"
              className="w-5 h-5 mr-2 hidden dark:inline"
            />
            <span>{t("archived_documents.filter_button")}</span>
          </button>
        </div>
      </div>

      {/* Document Table */}
      {loading ? (
        <div className="flex justify-center items-center h-full">
        <p className="text-gray-800 dark:text-gray-200 bg-gray-100 dark:bg-gray-900 text-sm px-4 py-2 rounded-lg shadow-md animate-pulse">
          {t("archived_documents.loading")}...
        </p>
      </div>      
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white dark:bg-gray-700 table-auto border-collapse">
            <thead>
              <tr className="text-gray-800 dark:text-gray-200 bg-gray-100 dark:bg-gray-900 text-sm">
                <th className="px-4 py-2]">{t("archived_documents.type")}</th>
                <th className="px-4 py-2">
                  {t("archived_documents.vendorCode")}
                </th>
                <th className="px-4 py-2">
                  {t("archived_documents.vendorName")}
                </th>
                <th className="px-4 py-2">{t("archived_documents.da")}</th>
                <th className="px-4 py-2">
                  {t("archived_documents.customer")}
                </th>
                <th className="px-4 py-2">
                  {t("archived_documents.invoiceId")}
                </th>
                <th className="px-4 py-2">
                  {t("archived_documents.invoiceTotal")}
                </th>
                <th className="px-4 py-2">{t("archived_documents.processingDate")}</th>
                <th className="px-4 py-2">
                  {t("archived_documents.dueDate")}
                </th>
                <th className="px-4 py-2">{t("archived_documents.status")}</th>
              </tr>
            </thead>
            <tbody className="overflow-y-auto">
              {paginatedDocuments.map((doc, index) => (
                <tr
                  key={index}
                  className="text-gray-600 text-sm dark:text-gray-200 border-b dark:border-gray-600 h-[75px]"
                >
                  <td className="px-4 py-2 h-[75px] overflow-hidden">
                  {doc.docType
                        ? t(`archived_documents.${doc.docType.toLowerCase()}`)
                        : "Doc"}
                  
                  </td>
                  <td className="px-4 py-2 h-[75px] overflow-hidden">
                    {doc.vendorSAPCode}
                  </td>
                  <td className="px-4 py-2 h-[75px] overflow-hidden">
                    {doc.vendorName.toUpperCase()}
                  </td>
                  <td className="text-center px-4 py-2 h-[75px] overflow-hidden">
                    {doc.trigram}
                  </td>
                  <td className="px-4 py-2 h-[75px] overflow-hidden">
                    {doc.customerName}
                  </td>
                  <td className="text-center px-4 py-2 h-[75px] overflow-hidden">
                    {doc.invoiceId}
                  </td>
                  <td className="text-right px-4 py-2 h-[75px] overflow-hidden">
                    {`${new Intl.NumberFormat(i18n.language, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(doc.invoiceTotal)} ${doc.currency}`}
                  </td>
                  <td className="text-center px-4 py-2 h-[75px] overflow-hidden">
                    {doc.invoiceDate
                      ? format(new Date(doc.invoiceDate), "dd/MM/yyyy")
                      : "--"}
                  </td>
                  <td className="text-center px-4 py-2 h-[75px] overflow-hidden">
                    {doc.dueDate
                      ? format(new Date(doc.dueDate), "dd/MM/yyyy")
                      : "--"}
                  </td>
                  <td className="px-4 py-2 h-[75px] overflow-hidden">
                    <span
                      className={`px-6 py-0.5 rounded-full text-xs font-semibold ${getStatusColor(
                        doc.docStatus
                      )}`}
                    >
                      {doc.docStatus
                        ? t(
                            `archived_documents.status_${doc.docStatus.toLowerCase()}`
                          )
                        : "Sent"}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination */}
      <div className="flex justify-between items-center mt-6">
        <div className="text-sm text-gray-600 dark:text-gray-400">
          {t("archived_documents.showing")} {currentPage}{" "}
          {t("archived_documents.of")} {totalPages}{" "}
          {t("archived_documents.pages")}
        </div>
        <div className="flex space-x-2">
          {[...Array(totalPages)].map((_, pageIndex) => (
            <button
              key={pageIndex}
              onClick={() => setCurrentPage(pageIndex + 1)}
              className={`px-4 py-2 text-base font-bold ${
                currentPage === pageIndex + 1
                  ? "text-indigo-500"
                  : "text-gray-700 dark:text-gray-200"
              }`}
            >
              {pageIndex + 1}
            </button>
          ))}
        </div>
      </div>

      {message && (
        <div className="fixed bottom-6 left-1/2 transform -translate-x-1/2 bg-red-500 text-white px-6 py-3 rounded-lg shadow-lg z-50">
          {message}
        </div>
      )}
    </div>
  );
};

// Helper function for status badge color
const getStatusColor = (status) => {
  if (!status) return "text-gray-600 bg-gray-200"; // Default color if status is undefined

  switch (status.toLowerCase()) {
    case "sent":
      return "text-green-600 bg-green-200";
    case "inprocessing":
      return "text-yellow-600 bg-yellow-200";
    case "failed":
      return "text-red-600 bg-red-200";
    case "cancelled":
      return "text-gray-600 bg-gray-200";
    default:
      return "text-gray-600 bg-gray-200"; // Default color for unrecognized status
  }
};

export default ArchivedDocuments;
