import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useTokenExpiration = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const expiresAt = localStorage.getItem("expiresAt");

    if (!expiresAt) return; // Gracefully handle missing expiration time

    const currentTime = Math.floor(Date.now() / 1000);
    const timeLeft = expiresAt - currentTime;

    if (timeLeft <= 0) {
      handleLogout();
      return;
    }

    const timeout = setTimeout(() => {
      handleLogout();
    }, timeLeft * 1000);

    return () => clearTimeout(timeout); // Cleanup on unmount
  }, [navigate]);

  const handleLogout = () => {
    //alert("Your session has expired. Please log in again.");
    localStorage.removeItem("token");
    localStorage.removeItem("expiresAt");
    navigate("/login?sessionExpired=true");
  };
};

export default useTokenExpiration;
